/* styles.css */

.e-grid .e-row {
  margin: 12px; /* Adjust the margin value as needed */
}

.e-grid .e-toolbar .e-tbar-btn-text,
.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
}

.e-grid .e-toolbar .e-btn {
  background-color: transparent !important;
  border: none !important; /* Remove outline from toolbar buttons */
  color: #348bff !important; /* Button text color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button {
  color: #348bff !important;
  padding: 5px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
}

.custom-icon-button {
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
  color: #348bff !important; /* Icon color */
}

.custom-icon-button i {
  margin-right: 0; /* Remove margin around icons */
  color: #348bff !important; /* Icon color */
}

/* Target the Syncfusion Grid header cells */
.e-gridheader {
  color: #348bff !important; /* Set header text color */
  font-weight: bold !important;
}

/* Ensure the header cell text color is applied to the entire header */
.e-grid .e-headercell {
  color: #348bff !important; /* Set header text color */
  font-weight: bold !important;
}

.e-headertext {
  font-weight: bold !important;
}

/* Custom Dialog Height */
.custom-dialog .e-dialog.e-popup {
  max-height: 600px !important; /* Adjust height as needed */
  height: auto !important;
}

/* Custom Dialog Content Height */
.custom-dialog .e-dialog .e-dlg-content {
  max-height: 500px !important; /* Adjust if needed to fit within dialog */
  overflow-y: auto;
}

/* Set Toolbar Background to Transparent */
.e-toolbar {
  background-color: transparent !important; /* Toolbar background color */
  color: #fff !important; /* Toolbar text color for better contrast */
}

/* Hover Effect for Toolbar Buttons */
.e-toolbar .e-toolbar-items .e-toolbar-item:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ) !important; /* Light background on hover */
  border-color: #fff !important; /* Ensure border color stays white on hover */
}

/* Focus Effect for Toolbar Buttons */
.e-toolbar .e-toolbar-items .e-toolbar-item:focus {
  outline: none; /* Remove default outline */
}

.text-title {
  @apply text-left text-[#193861];
  font-size: 15px;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  background-color: transparent !important;
}

.e-icon-dlg-close {
  color: #fff;
}

.e-dlg-header-content {
  background-color: #348bff !important;
}

.e-dlg-header,
.e-dlg-header * {
  color: #fff !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background: transparent;
  border-color: transparent;
  color: #348bff !important;
}

.e-float-line {
  color: #348bff !important;
}

/* Change the font family for the entire grid */
.e-grid .e-headercell,
.e-grid .e-rowcell {
  font-family: "Inter", sans-serif;
}

/* Change the font color for the grid header cells */
.e-grid .e-headercell {
  color: #348bff; /* Change this to your desired color */
}

/* Change the font color for the grid row cells */
.e-grid .e-rowcell {
  color: #000000; /* Change this to your desired color */
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #e6f0ff;
  color: #0066ff;
  border: 2px solid #0066ff;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background-color: #d9e6ff;
}

.upload-button svg {
  width: 20px;
  height: 20px;
}

.z-0.w-full.flex-grow {
  height: 90vh !important;
  width: 100% !important;
}

#maps_control_1_tile_parent {
  height: 90vh !important;
  width: 100% !important;
}

/* Hide the arrows in Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.error-outline {
  border-color: red !important;
}

@media (max-width: 1024px) {
  .login-form {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .login-form {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .login-desc {
    display: none;
  }

  input {
    width: 100%;
  }
}

#grid_1712249876_2_dialogEdit_wrapper {
  max-height: 600px !important;
}

@media (max-width: 480px) {
  .login-form {
    width: 100%;
    padding: 10px;
  }

  input {
    width: 100%;
  }

  /* button {
    width: 100%;
  } */
}

.custom-grid .e-gridheader {
  display: none;
}

.custom-grid {
  border: none; /* Removes top border between content and header */
}

/* NEW THEME DESIGN START HERE */
/* LOGIN */
.bg-login-right-panel {
  background: #467ba4;
}

.login-logo-label {
  color: #fdb841;
  font-size: 40px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.bg-login-btn {
  background: #467ba4;
  color: #fdb841;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
}
.login-download-mobile {
  color: #467ba4;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* REGISTER */
.user-type-dropdown {
  color: #fff !important;
  background: #467ba4 !important;
}

.user-type-dropdown .e-float-input.e-input-group .e-input-group-icon,
.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
  color: white !important;
}

/* HEADER AND NAV */
header-powered-by {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: var(--font-weight-500, 500);
  line-height: normal;
}

.header-logo-label {
  color: #fdb841;
  text-shadow: 1px 1px 4px #195380;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: var(--font-weight-600, 600);
  line-height: normal;
}

.bg-header-panel {
  background: #467ba4;
}

.bg-nav-active {
  background: #ffd782;
}

/* BUTTONS */
.btn-default {
  border-radius: 8px;
  background: #467ba4;
  color: #fdb841;
  text-align: center;
  font-family: Inter;
  font-size: 13.3px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn-default-border {
  border-radius: 8px;
  border: var(--stroke-weight-1, 1px) solid #467ba4;
  color: #467ba4;
  text-align: center;
  font-family: Inter;
  font-size: 13.3px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn-cancel {
  border-radius: 8px;
  border: var(--stroke-weight-1, 1px) solid #9c1717;
  color: #9c1717;
  text-align: center;
  font-family: Inter;
  font-size: 13.3px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn-gray {
  border-radius: 8px;
  background: #adb5bd;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13.3px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

svg.h-3.w-3 {
  margin-top: 2px;
}

/* DIALOGS */

.e-dlg-header-content {
  background-color: #467ba4 !important;
}

.e-dlg-header {
  color: #fdb841;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  font-family: var(--font-family-Font-1, Inter);
  font-size: var(--font-size-24, 24px);
  font-style: normal;
  font-weight: var(--font-weight-700, 700);
  line-height: normal;
}

.e-dialog .e-dlg-header {
  color: #fdb841 !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) !important;
  font-family: var(--font-family-Font-1, Inter) !important;
  font-size: var(--font-size-24, 24px) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-700, 700) !important;
  line-height: normal !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
}

/* Style for Save button (primary) */
.e-dialog .e-footer-content .e-primary {
  background-color: #467ba4 !important;
  color: #fdb841 !important;
  border-radius: 8px;
}

/* Style for Cancel button (non-primary) */
.e-dialog .e-footer-content .e-btn:not(.e-primary) {
  background-color: #ffffff !important;
  color: #9c1717 !important;
  border: 1px solid #9c1717 !important;
  border-radius: 8px;
}

.bg-gold {
  background-color: #fdb841;
  color: #9c1717;
}

.bg-blue {
  background-color: #467ba4;
  color: #fdb841;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: var(--font-size-24, 24px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user-profile-thumbnail {
  background-color: #467ba4;
  color: #fdb841;
  font-family: var(--font-family-Font-1, Inter);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chat-user-thumbnail {
  background-color: #467ba4;
  color: #fdb841;
  font-family: var(--font-family-Font-1, Inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* PROFILE */

.e-float-input.e-input-group .e-input-group-icon,
.e-float-input.e-control-wrapper.e-input-group .e-input-group-icon {
  color: #595858 !important;
}

.custom-grid-dialog .e-dialog {
  max-width: 60vw !important;
  max-height: 70vh !important;
}

.e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
  margin: 0 !important;
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
  padding: 0 !important;
}

/* PROFILE */
.commu-title {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1px;
}

.chat-textarea {
  background: #eaeaea;
  border-radius: 5px;
}

.commu-left-panel {
  max-width: 275px;
  min-height: 100%;
  max-height: 100%;
}

.commu-right-panel {
  border-left: 1px solid #d9d9d9;
}

.btn-commu-add {
  border-radius: 0 8px 8px 0;
  background: #467ba4;
  color: #fdb841;
  text-align: center;
  font-family: Inter;
  font-size: 13.3px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.convo-title {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.5px;
}

.convo-title-bold {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px; /* 150% */
  letter-spacing: -0.5px;
}

/* JOB OPPORTUNITIES */
.job-card-cont {
  padding-right: 5px;
  padding-top: 10px;
}

.job-card {
  border: 1px solid gainsboro;
}

.job-card-selected {
  border: 2px solid #467ba4;
}

.textarea-default {
  width: 100%;
}

.popup-error-message .e-dialog .e-dlg-content {
  color: red !important;
  font-family: "Inter", sans-serif !important;
  padding-top: 10px !important;
  padding: 4rem !important;
}

/* More specific selector */
.popup-error-message .e-dialog .e-dlg-content * {
  color: red !important; /* Apply to all child elements */
}

.e-multiselect-wrapper.e-error {
  border-color: #dc2626 !important; /* red-600 */
}

.e-multiselect.e-error {
  border-color: #dc2626 !important;
}

.e-icons.e-columnmenu {
  margin-top: -25px;
}


.e-grid .e-icons:not(.e-btn-icon) {
    color: #348bff !important;
    font-size: 12px !important;
}

/* Disaster Declaration */
.disaster-card {
    border-radius: 5px;
    border: 1px solid #dcdcdc;
}

.disaster-card-title {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.disaster-card-body {

}

.disaster-type {
    height: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    font-family: Inter;
    font-size: 10spx;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 6px;
}

.disaster-type-icon {
    margin: 5px 0 0 0;
}

.view-details-btn {
    color: var(--DARCI-Primary-Color, #003460);
    /* Inter/Regular Default */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.disaster-header-label {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* JOURNAL CSS */
.journal-textarea-cont {
    border-radius: 4px;
    border: 1px solid var(--Border-Color, #CFCFCF);
    background: #FFF;
}

.transition-transform {
  transition: transform 0.3s ease-in-out; /* Smooth transition for transform */
}

.translate-x-full {
  transform: translateX(100%); /* Move off-screen to the right */
}

.translate-x-0 {
  transform: translateX(0); /* Move to original position */
}